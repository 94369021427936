
import { Vue, Component, Watch, Prop } from "vue-property-decorator"
import ConsultantProfile from "./consultant/ConsultantProfile.vue"
import {
  ConsultantProfileQuery,
  UserProfileQuery,
  MyPerformanceAppraisalsQuery,
  MissionPerformanceAppraisal,
  MissionLifecycleBasic,
} from "@/gql"
import BasicInfo from "@/components/consultant/BasicInfo.vue"
import AppDetailPage from "@/components/layout/AppDetailPage.vue"
import AppPage from "@/components/layout/AppPage.vue"

import UpdateAvatar from "@/views/consultant/UpdateAvatar.vue"
import { DocumentNode } from "graphql"
import Loader from "@/components/widgets/common/Loader.vue"
import { ApolloError } from "@apollo/client/core"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"
import { SmartQuery } from "vue-apollo/types/vue-apollo"
import { ApolloQueryResult, NetworkStatus } from "@apollo/client/core"
import AppraisalCard from "@/components/profile/AppraisalCard.vue"
import EditProfile from "@/views/consultant/EditProfile.vue"
import ApplyPeralteeForm from "@/components/consultant/forms/ApplyPeralteeForm.vue"
import Education from "@/components/consultant/Education.vue"
import WorkExperience from "@/components/consultant/WorkExperience.vue"
import KeywordField from "@/components/fields/KeywordField.vue"

@Component({
  components: {
    ConsultantProfile,
    BasicInfo,
    AppDetailPage,
    AppPage,
    UpdateAvatar,
    Loader,
    FeedBackMessage,
    AppraisalCard,
    EditProfile,
    ApplyPeralteeForm,
    Education,
    WorkExperience,
    KeywordField,
  },
})
export default class MyProfile extends Vue {
  readonly consultantProfileQuery = ConsultantProfileQuery
  readonly myPerformanceAppraisalsQuery = MyPerformanceAppraisalsQuery
  consultant: any = null
  appraisals: MissionPerformanceAppraisal[] = []
  missions: MissionLifecycleBasic[] = []
  query: DocumentNode | null = null
  loadingError = false
  networkError = false
  loading = false
  isLoadingMore = false
  showApplyPeralteeDialog = false
  showEditModal = false
  showAppraisalModal = false
  showMoreInfo = false

  @Prop() readonly editMode?: boolean

  getInitials(name: string) {
    const names = name.split(" ")
    const initials = names.map((n) => n.charAt(0)).join("")
    return initials.toUpperCase()
  }

  get isOwnProfile() {
    return this.currentUser?.consultant?.id == this.consultant?.id
  }

  get isTalent(): boolean {
    return this.can("edit_talent") && ["approved", "shortlisted"].includes(this.consultant.state)
  }

  onComplete(result: any) {
    if (result.data && result.data.consultantProfile) {
      this.consultant = result.data.consultantProfile
    }
  }

  onResult(result: ApolloQueryResult<any>) {
    if (result.networkStatus === NetworkStatus.ready) {
      this.loading = false
      this.loadingError = false

      if (result.data && result.data.profile) {
        this.consultant = {
          user: {
            id: result.data.profile.id,
            avatar: result.data.profile.avatar,
          },
          email: result.data.profile.email,
          firstName: result.data.profile.firstName,
          lastName: result.data.profile.lastName,
        }
      }

      if (result.data && result.data.consultantProfile) {
        this.consultant = result.data.consultantProfile
        this.missions = result.data.consultantProfile.missions.data || []
      }
    } else if (result.networkStatus === NetworkStatus.error) {
      this.loadingError = true
    }
  }

  onError(error: ApolloError) {
    if (error.graphQLErrors) {
      this.loadingError = true
    } else if (error.networkError) {
      this.networkError = true
    }
  }

  async handleLoadingErrorButtonClick(query: SmartQuery<any>) {
    this.$set(this, "loading", true)
    await query.refetch()
  }

  onEditModalClose() {
    this.$emit("update:editMode", false)
  }

  mounted() {
    this.query =
      this.isCoreTeam() && !(this.currentUser && this.currentUser.consultant)
        ? UserProfileQuery
        : ConsultantProfileQuery
  }

  @Watch("editMode")
  onEditModeChange() {
    if (this.editMode) {
      this.showEditModal = true
    }
  }
}
